.crashpad-layout[layout="B"] .crashpad-accent-2-2 {
  display: none;
}
.crashpad-layout[layout="B"] .crashpad-accent-1 {
  top: 0;
  left: -16vh;
  transform: skew(-40deg);
}
.crashpad-layout[layout="B"] .crashpad-accent-cover-1 {
  top: 0;
  left: -30vh;
  transform: skew(-55deg);
}
.crashpad-layout[layout="B"] .crashpad-accent-1-2 {
  bottom: 0;
  right: -22vh;
  transform: skew(-42deg);
}
.crashpad-layout[layout="B"] .crashpad-accent-2 {
  bottom: 0;
  right: -18vh;
  transform: skew(-55deg);
}
.crashpad-layout[layout="B"] .crashpad-accent-cover-2 {
  bottom: 0;
  right: -24vh;
  transform: skew(-24deg);
}
